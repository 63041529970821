<template>
  <div id="spatial-map">
    <l-map
      id="l-map"
      ref="dist_map"
      :zoom="2"
      :center="center"
      :maxBounds="maxBounds"
      :minZoom="1"
      :maxZoom="15"
      :style="'height:' + height"
      @click="addMarker"
    >
    </l-map>
    <div v-if="options" class="icon-bar">
      <b-icon-sliders v-if="!show_options" font-scale="1.75" class="show-options"
                      @click="toggleOptions"></b-icon-sliders>
      <b-icon-x-circle v-if="show_options" font-scale="1.75" class="show-options"
                       @click="toggleOptions"></b-icon-x-circle>
    </div>

    <b-col lg="3" md="4" v-if="show_options" class="map-options">
      <div class="marker-settings">
        <p>Marker settings</p>
        <div class="map-slider">
          <label>Marker radius: {{ markerCircleRadius / 1000 }}km</label>
          <b-form-input
            id="range-circle"
            v-model="markerCircleRadius"
            type="range"
            min="5000"
            max="1000000"
            step="2000"
          ></b-form-input>
        </div>
      </div>

      <span>Planned features:</span>
      <!--TODO: Implement country, grid search + increase tooltip time -->
      <div class="layer-settings">
        <div disabled>
          <p>Layer Settings</p>
          <b-form-checkbox
            v-b-tooltip.hover="{
          title: 'Make countries selectable as an area. Disables the placement of markers.',
          delay: 100}"
            v-model="show_countries"
            size="md"
            switch
            disabled
          >Country borders
          </b-form-checkbox>
          <b-form-checkbox
            v-b-tooltip.hover="{
          title: 'Tiles the map into squares selectable as an area. Disables the placement of markers.',
          delay: 100}"
            v-model="show_grid"
            size="md"
            @change=""
            switch
            disabled
          >Grid overlay
          </b-form-checkbox>
          <div class="map-slider" v-if="show_grid">
            <label>Grid square size: {{ gridSquareSize }}km²</label>
            <b-form-input
              id="range-grid"
              v-model="gridSquareSize"
              type="range"
              min="5"
              max="100"
              step="1"
            ></b-form-input>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet'
import { isEmpty } from 'lodash'

const accessToken = process.env.VUE_APP_MAPBOX_TOKEN

export default {
  name: 'spatial-map',
  components: {
    LMap,
    LTileLayer
  },
  props: {
    options: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '520px'
    }
  },
  data () {
    return {
      attribution: '© <a href=\"https://www.mapbox.com/feedback/\">Mapbox</a> © <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/1tastyrainbow/ckb2hqunx17471jnp8e631nay/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken,
      center: [26.16, 66.44],
      show_countries: false,
      show_grid: false,
      show_options: false,
      map: {},
      marker: {},
      countriesLayer: {},
      countriesLayerGroup: {},
      markerIcon: {},
      markerIconOptions: {
        iconSize: [25, 40],
        iconAnchor: [12.5, 40],
        popupAnchor: [75, 20],
        iconUrl: 'data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJtYXAtcGluIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtbWFwLXBpbiBmYS13LTkiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjg4IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTEyIDMxNi45NHYxNTYuNjlsMjIuMDIgMzMuMDJjNC43NSA3LjEyIDE1LjIyIDcuMTIgMTkuOTcgMEwxNzYgNDczLjYzVjMxNi45NGMtMTAuMzkgMS45Mi0yMS4wNiAzLjA2LTMyIDMuMDZzLTIxLjYxLTEuMTQtMzItMy4wNnpNMTQ0IDBDNjQuNDcgMCAwIDY0LjQ3IDAgMTQ0czY0LjQ3IDE0NCAxNDQgMTQ0IDE0NC02NC40NyAxNDQtMTQ0UzIyMy41MyAwIDE0NCAwem0wIDc2Yy0zNy41IDAtNjggMzAuNS02OCA2OCAwIDYuNjItNS4zOCAxMi0xMiAxMnMtMTItNS4zOC0xMi0xMmMwLTUwLjczIDQxLjI4LTkyIDkyLTkyIDYuNjIgMCAxMiA1LjM4IDEyIDEycy01LjM4IDEyLTEyIDEyeiI+PC9wYXRoPjwvc3ZnPg=='
      },
      markerCircleRadius: 50000,
      gridSquareSize: 50
    }
  },
  computed: {
    maxBounds () {
      const cSouthWest = L.latLng(-180, -90)
      const cNortEast = L.latLng(180, 90)
      return L.latLngBounds(cSouthWest, cNortEast).pad(0.85)
    }
  },
  mounted () {
    this.markerIcon = new L.Icon(this.markerIconOptions)

    this.$nextTick(() => {
      this.map = this.$refs.dist_map.mapObject
      this.map.doubleClickZoom.disable()

      const mapboxTiles = L.tileLayer(this.url, {
        attribution: this.attribution,
        tileSize: 512,
        zoomOffset: -1
      })
      this.map.addLayer(mapboxTiles)
    })

    document.getElementById('l-map').addEventListener('removeMarker', () => {
      this.$store.dispatch('spatialMap/removeMarker')
    })
  },
  methods: {
    toggleOptions () {
      this.show_options = !this.show_options
    },
    addMarker (clickedPos) {
      let markerCircle = new L.Circle(clickedPos.latlng, {
        radius: parseInt(this.markerCircleRadius),
        color: '#3CB8B3'
      })
      let marker = new L.Marker(clickedPos.latlng, {
        draggable: true,
        autoPan: true,
        icon: this.markerIcon,
        circle: markerCircle
      })
      marker.addTo(this.map)
      markerCircle.addTo(this.map)
      this.marker = marker

      let markerData = {
        latLng: marker.getLatLng(),
        radius: markerCircle.getRadius()
      }
      this.$store.dispatch('spatialMap/addMarker', markerData)

      marker.on('drag', (e) => {
        marker.options.circle.setLatLng(e.target.getLatLng())
      })
      marker.on('dragend', () => {
        this.$store.dispatch('spatialMap/addMarker', markerData)
      })
    }
  },
  watch: {
    marker: function (newMarker, oldMarker) {
      if (!isEmpty(oldMarker)) {
        oldMarker.remove()
        oldMarker.options.circle.remove()
      }
    },
    markerCircleRadius: function (newVal) {
      if (!isEmpty(this.marker)) {
        this.marker.options.circle.setRadius(newVal)
        this.$store.dispatch('spatialMap/addMarker', {
          latLng: this.marker.getLatLng(),
          radius: parseInt(this.markerCircleRadius)
        })
      }
    }

    /*
  toggleCountries (checked) {
    checked ? this.addCountries() : this.removeCountries()
  },
  addCountries () {
    if (Object.entries(this.countriesLayer).length === 0) this.initCountries()
    this.countriesLayerGroup.addLayer(this.countriesLayer)
    this.map.addLayer(this.countriesLayerGroup)
  },
  removeCountries () {
    this.countriesLayerGroup.clearLayers()
  }
  ,
  initCountries () {
    const countries = require('@/assets/world-countries')
    this.countriesLayerGroup = new L.LayerGroup()

    const style = {
      stroke: true,
      opacity: 0.25,
      fill: true,
      fillColor: 'transparent'
    }

    this.countriesLayer =
      L.geoJson(countries, {
        onEachFeature: this.onEachCountry,
        clickable: true,
        style: style
      })
  },
  onEachCountry (feature, layer) {
    layer.on('click', function () {
      console.log(layer.feature.properties.name)
    })
  }
     */
  }
}
</script>

<style lang="scss">
#spatial-map {
  position: relative;
  margin: 0 auto;

  #l-map {
    width: 100%;
    height: 520px;
  }

  .icon-bar {
    position: absolute;
    margin: 15px 15px;
    padding: 0.5em;
    top: 0;
    right: 0;
    z-index: 410;
    background: white;
    border-radius: 10px;
    box-shadow: 6px 8px 16px -8px black;

    .show-options {
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .map-options {
    position: absolute;
    background: white;
    padding: 1rem 3rem 3rem 3rem;
    margin: 3rem auto;
    min-width: 345px;
    font-weight: 300;
    border-radius: 10px 0 0 10px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 400;

    p {
      font-size: 1em;
      font-weight: 500;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    }

    .layer-settings, .marker-settings {
      margin: 1rem 0;
    }

    .layer-settings {
      color: $grey-light !important;

      label {
        color: $grey-light !important;
      }
    }

    .custom-switch {
      margin-bottom: 1rem;
      max-width: 225px;

      .custom-control-label {
        padding-left: 0.5em !important;
      }
    }

    .map-slider {
      @include desktop-huge-up {
        max-width: 225px;
      }

      input[type="range"] {
        display: block;
        margin-bottom: 0.75em;
      }
    }
  }

  .leaflet-bar {
    border: none;
    border-radius: 10px;
    box-shadow: 6px 8px 16px -8px black
  }

  .leaflet-popup-tip-container {
    height: 50px !important;
    left: unset !important;
    top: 0 !important;

    .leaflet-popup-tip {
      margin: 25px auto 0 !important;
      box-shadow: -3px 3px 3px -1px rgba(0, 0, 0, 0.4) !important;
    }
  }
}
</style>
