<template>
  <article class="tile">
    <router-link :to="url">
      <div>
        <div class="tile-img" :style="{'background-image': `url(${img})`}"></div>
        <p class="tile-title">{{ title }}</p>
      </div>
    </router-link>
  </article>
</template>

<script>
export default {
  name: 'Tile',
  props: {
    title: String,
    img: String,
    url: String
  },
  computed: {
    publicPath: () => {
      return process.env.BASE_URL
    }
  }
}
</script>

<style lang="scss">
.tile {
  flex: 1 1 auto;
  margin: 0rem;
  width: calc(100% / 3 - (0.75rem * 2));

  @include smartphone-only {
    width: calc(100% / 2 - (0.75rem * 2));
  }

  @include tablet-up {
    margin: 0.5rem;
    width: calc(100% / 3 - (0.5rem * 2 + 0.75rem * 2));
  }

  &:nth-last-of-type(-n+3) {
    .tile-img {
      background-position-x: right;
    }
  }

  a {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    > div {
      padding: 0.75rem;
      border-radius: 10px;

      &:hover {
        background-color: $grey-disabled;
      }

      .tile-img {
        height: 125px;
        max-width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: top;
        background-position-x: left;
        border-radius: 10px;

        @include tablet-up {
          height: 150px;
        }
      }
    }
  }

  .tile-title {
    padding-top: 0.5em;
    color: $black;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.015em;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;

    @include tablet-up {
      font-size: 1.25em;
    }
  }
}
</style>
