<template>
  <b-container id="search-form" fluid>
    <b-row align-h="center">
      <b-col cols="12" md="8" sm="10">
        <quick-search v-bind:active="!QS_active"></quick-search>
        <div class="text-right">
          <span class="underline-scaled-blue" v-on:click="openAdvancedSearch">Advanced Search</span>
        </div>
        <advanced-search></advanced-search>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import QuickSearch from './QuickSearch'
import AdvancedSearch from './AdvancedSearch'

export default {
  name: 'search-form',
  components: {
    AdvancedSearch,
    QuickSearch
  },
  methods: {
    openAdvancedSearch: function () {
      const advancedSearch = document.getElementById('advanced-search')

      // offsetParent is null if the element is not visible
      if (advancedSearch.offsetParent === null) {
        advancedSearch.style.display = 'block'
        this.QS_active = false
      } else {
        advancedSearch.style.display = 'none'
        this.QS_active = true
      }
    }
  },
  data () {
    return {
      errors: [],
      QS_active: true
    }
  }
}
</script>

<style lang="scss" scoped>
#search-form {
  margin: 2rem 0;

  @include tablet-up {
    margin: 6rem 0;
  }
  @include desktop-large-up {
    margin: 8rem 0;
  }

  span {
    display: inline-block;
    position: relative;
    margin: 1rem 0.5rem 2rem 0;
    text-transform: uppercase;
  }

  span:hover {
    cursor: pointer;
  }

  .form-control:focus {
    box-shadow: unset !important;
  }
}
</style>
