<template>
  <div id="geographic-search">
    <SpatialMap></SpatialMap>
    <div class="searchbar">
      <b-button :disabled="!isSearchable" class="btn-search" pill
                size="lg"
                variant="info"
                @click="onConfirm()">Search
      </b-button>
    </div>
  </div>
</template>

<script>
import SpatialMap from '../base/SpatialMap'
import router from '@/router/router'
import { isEmpty } from 'lodash'

export default {
  name: 'GeographicSearch',
  components: {
    SpatialMap
  },
  computed: {
    isSearchable () {
      return !isEmpty(this.$store.getters['spatialMap/markerCoordinates'])
    }
  },
  methods: {
    onConfirm () {
      this.$store.commit("search/setType", "spatial")
      router.push({ name: 'Results' })
    }
  }
}
</script>

<style lang="scss" scoped>
#geographic-search {
  position: relative;

  .searchbar {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 25px;
    pointer-events: none;
    z-index: 1100;

    .btn-search {
      width: 150px;
      margin-right: 10px;
      pointer-events: all;
      background-color: $color-accent;
      border-color: $color-accent;

      &:hover {
        background-color: $color-accent-hover;
        border-color: $color-accent-hover;
      }
    }
  }
}
</style>
