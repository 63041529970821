<template>
  <b-container fluid id="landing">
    <b-row class="pl-sm-5 p-md-0">
      <b-col offset="1" offset-sm="0" offset-md="1" cols="10" sm="6" md="5" lg="4">
        <b-row>
          <h1 class="title mb-0">Search the reptile database</h1>
          <div class="title-decoration"></div>
          <div class="title-decoration"></div>
        </b-row>
        <b-row>
          <h2 class="subtitle">
            Providing a catalogue of all living reptile species and their classification.
          </h2>
        </b-row>
      </b-col>
      <b-col class="stats d-sm-flex mt-md-0" sm="6" md="6">
        <div class="stats-box">
          <p>11,339</p>
          <p>Species</p>
        </div>
        <div class="stats-box">
          <p>9,864</p>
          <p>Maps</p>
        </div>
        <div class="stats-box">
          <p>51,309</p>
          <p>References</p>
        </div>
      </b-col>
    </b-row>

    <search-form></search-form>

    <b-container fluid id="landing-articles">
      <b-row>
        <b-col cols="12" lg="7" offset="0" offset-lg="1" id="higher-taxa">
          <h1 class="title">Higher Taxa</h1>
          <b-row>
            <Tile title="lizards" url="/taxa/lizards"
                  :img="require('@/assets/pictures/Amphibolurus_muricatus2_small.jpg')"></Tile>
            <Tile title="snakes" url="/taxa/snakes"
                  :img="require('@/assets/pictures/Demansia_psammophis_small.jpg')"></Tile>
            <Tile title="tuataras" url="/taxa/tuataras"
                  :img="require('@/assets/pictures/Sphenodon_punctatus2_small.jpg')"></Tile>
            <Tile title="crocodiles" url="/taxa/crocodiles"
                  :img="require('@/assets/pictures/Crocodylus_johnsoni3_small.jpg')"></Tile>
            <Tile title="Amphisbaenia" url="/taxa/amphisbaenia"
                  :img="require('@/assets/pictures/Amphisbaena_camura_small.jpg')"></Tile>
            <Tile title="turtles" url="/taxa/turtles"
                  :img="require('@/assets/pictures/Chrysemys_picta15_small.jpg')"></Tile>
          </b-row>
        </b-col>
        <b-col cols="12" lg="4" id="news">
          <h1 class="title">News</h1>
          <div>
            <h3>Newsletter</h3>
            <router-link to="/about/news">
              <p>17 December 2020 -- NEW RELEASE!</p>
            </router-link>
          </div>
          <div>
            <h3>New Publications</h3>
            <router-link to="/publications">
              <p>From the Reptile Database itself and related.</p>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid id="landing-geo">
      <b-row align-v="center">
        <b-col cols="12" md="4" lg="3" offset-lg="1" class="geo-col">
          <h1 class="title">Geo-Search</h1>
        </b-col>
        <b-col cols="12" md="7" lg="6" offset-md="1" class="geo-col">
          <p>
            You can now find reptile species within a geographic radius by selecting a map area.
            The given location will be compared with existing distribution information of ~9800 species to find reptiles
            occurrences.
          </p>
        </b-col>
      </b-row>
      <GeographicSearch></GeographicSearch>
    </b-container>
  </b-container>
</template>
<script>
import GeographicSearch from '@/components/search/GeographicSearch'
import Tile from '@/components/base/Tile'
import SearchForm from '@/components/search/SearchForm'

export default {
  name: 'Landing',
  components: {
    SearchForm,
    Tile,
    GeographicSearch
  }
}
</script>
<style lang="scss" type="scss">
#landing {
    margin-bottom: 0;

  > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  h1 {
    margin-bottom: 0.5em;
  }

  h3 {

  }

  .vector1 {
    position: absolute;
    width: 341.5px;
    height: 143px;
    left: 1207px;
    top: 484px;
    border: 1px solid #000000;
  }

  .title-decoration {
    position: absolute;
    max-width: 100vw;
    width: 870px;
    height: 538px;

    &:first-of-type {
      background-color: lighten($grey-disabled, 6%);
      z-index: -2;
      left: -100px;
      top: 38px;
    }

    &:last-of-type {
      background-color: lighten($color-accent, 48%);
      z-index: -1;
      left: -120px;
      top: 70px;
    }
  }

  .subtitle {
    margin-top: 1em;
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 28px;
    color: $grey-dark;

    @include tablet-up {
      font-size: 1.25em;
      margin-top: 2em;
    }
    @include smartphone-only {
      margin-top: 1em;
    }
  }

  .stats {
    flex-wrap: wrap;
    display: none;
    justify-content: space-around;
    align-content: baseline;
    align-items: baseline;

    @include desktop-large-up {
      margin-left: 2rem;
    }
    @include desktop-huge-up {
      margin-left: 4rem;
    }

    .stats-box {
      padding-top: 1em;
      margin-right: 2rem;
      margin-left: 4rem;

      @include tablet-up {
        width: 195px;
        padding-top: 2em;
        margin-right: 3rem;
        margin-left: 0;
      }
      @include desktop-large-up {
        width: 200px;
      }

      &:first-child {
        justify-self: center;
        padding-top: 0;

        @include tablet-up {
          width: 100%;
          margin-left: 25%;
        }
        @include desktop-large-up {
          margin-top: 2.25rem;
        }
      }

      p:first-child {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.75em;
        line-height: 48px;
        letter-spacing: 0.045em;
        color: $grey-dark;

        @include desktop-large-up {
          font-size: 2.25em;
        }
        @include desktop-huge-up {
          font-size: 2.5em;
        }
      }

      p:last-child {
        font-size: 0.75em;
        font-weight: 300;
        letter-spacing: 0.045em;
        text-transform: uppercase;

        @include tablet-up {
          font-size: 1em;
          letter-spacing: unset;
        }
        @include desktop-large-up {
          font-size: 1.25em;
        }
      }
    }
  }

  p {
    margin-bottom: unset;
  }

  #landing-articles {
    > .row {
      padding: 5rem 0;
      background-color: $grey-background;
    }

    #higher-taxa {
      padding: 2rem 2rem;

      @include smartphone-up {
        padding: 2rem 4rem;
      }
      @include desktop-large-up {
        padding: 2rem 3rem 0 0;
      }
    }

    #news {
      padding: 2rem 2rem;
      border-radius: 10px 0 0 10px;
      background-color: $grey-disabled;

      @include smartphone-up {
        padding: 2rem 4rem;
      }
      @include desktop-large-up {
        padding: 2rem 6rem 0 4rem;
      }
      @include desktop-huge-up {
        padding: 2rem 8rem 0 4rem;
      }

      > div {
        padding: 1em 0;

        &:last-child {
          margin-top: 2.25rem;
        }
      }

      h3 {
        margin-top: 0;
        color: $black;
        font-size: 1.5em;
        letter-spacing: 0.045em;
        text-transform: uppercase;
      }

      a {
        color: $black;

        &:hover {
          color: $color-accent-hover;
        }
      }

      p {
        font-size: 1.25em;
        border: 1px solid black;
        border-radius: 5px;
        padding: 0.75em;
      }

    }
  }

  #landing-geo {
    margin-bottom: 0;

    > .row {
      padding: 4rem 2rem;

      @include smartphone-up {
        padding: 4rem;
      }
      @include desktop-huge-up {
        padding: 4rem 0;
      }

      .geo-col {
        @include tablet-down {
          margin-left: -15px;
        }
      }
    }

    @include desktop-large-up {
      margin-top: 4rem;
    }

    .title {
      @include tablet-up {
        margin-bottom: 0;
      }
    }

    #geographic-search {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

</style>
