<template>
  <div id="advanced-search">
    <b-row class="as-header">
      <b-col class="d-none d-lg-block" cols="0" lg="3"><span>Field</span></b-col>
      <b-col class="d-none d-lg-block" cols="0" lg="8"><span>Term</span></b-col>
      <b-col class="col-exact p-sm-0" cols="3" lg="1" sm="2">
        <span
          v-b-tooltip.hover="{
          title: 'Term is required to be a 100% match.',
          delay: 100}">Exact<font-awesome-icon :icon="['far', 'question-circle']" class="faq"/>
        </span>
      </b-col>
    </b-row>
    <b-form class="as-form" v-on:submit.prevent="search()" ref="searchForm">
      <b-row v-for="field in fields" :key="field.label + 'Row'">
        <b-col class="mb-1 mb-sm-2 mb-lg-0" cols="12" lg="3">
          <label :for="field.label + 'Input'">{{ field.label }}</label>
        </b-col>
        <b-col cols="10" lg="8">
          <input :id="field.label + 'Input'" v-model="field.value" :name="field.label" :placeholder="field.placeholder"
                 class="form-control"
                 type="text">
        </b-col>
        <b-col cols="2" lg="1">
          <b-form-checkbox v-model="field.exact" :name="field.label" class="form-check-input"
                           size="lg"
                           type="checkbox"></b-form-checkbox>
        </b-col>
      </b-row>
      <b-button size="lg" type="submit" variant="light">Search</b-button>
    </b-form>
  </div>
</template>

<script>
import router from '@/router/router'

const trimEnd = require('lodash/trimEnd')

export default {
  name: 'advanced-search',
  props: {},
  data () {
    return {
      fields: [
        {
          label: 'Higher Taxa',
          placeholder: 'Lizards',
          value: '',
          alias: 'taxa',
          exact: false
        },
        {
          label: 'Genus',
          placeholder: 'Sphaerodactylus',
          alias: 'genus',
          value: ''
        },
        {
          label: 'Species',
          placeholder: 'difficilis',
          value: '',
          alias: 'species',
          exact: false
        },
        {
          label: 'Subspecies',
          placeholder: 'anthracomus',
          value: '',
          alias: 'subspecies',
          exact: false
        },
        {
          label: 'Author',
          placeholder: 'Barbour',
          value: '',
          alias: 'author',
          exact: false
        },
        {
          label: 'Year',
          placeholder: '1990',
          value: '',
          alias: 'year',
          exact: false
        },
        {
          label: 'Common Name',
          placeholder: 'Difficult Least Gecko',
          value: '',
          alias: 'common_name',
          exact: false
        },
        {
          label: 'Distribution',
          placeholder: 'Dominican Republic',
          value: '',
          alias: 'distribution',
          exact: false
        },
        {
          label: 'Types',
          placeholder: 'Holotype',
          value: '',
          alias: 'types',
          exact: false
        },
        {
          label: 'References',
          placeholder: 'Sphaerodactylus difficilis',
          value: '',
          alias: 'references',
          exact: false
        },
      ]
    }
  },
  methods: {
    search () {
      let queryString = ''
      let fieldsNotEmpty = this.fields.filter(field => {
        return field.value !== ''
      })

      if (fieldsNotEmpty.length < 1) {
        return
      }

      let exactMatches = fieldsNotEmpty.filter(field => {
        return field.exact
      }).map(field => {
        return field.alias
      }).toString()

      fieldsNotEmpty.forEach(function (field, index) {
        let queryParam = ''
        queryParam += field.alias + '=' + trimEnd(field.value, ' ')

        if (index < fieldsNotEmpty.length - 1) {
          queryString += queryParam + '&'
        } else {
          queryString += queryParam
        }
      })

      queryString += exactMatches !== '' ? '&em=' + exactMatches : ''
      queryString = '/search?' + queryString

      router.push(queryString)
    }
  }
}
</script>

<style lang="scss">
#advanced-search {
  display: none;
  font-size: 1.25em;
  font-weight: 300;
  padding: 3em;
  border: 1px solid $grey-disabled;
  border-radius: 10px;
  background-color: white;
  box-shadow: 4px 10px 15px 0 $grey-disabled;

  @include smartphone-only {
    padding: 1.75em;
  }

  .as-header {
    color: $grey-light;
    justify-content: flex-end;

    @include desktop-large-up {
      margin-bottom: 2em;
      justify-content: unset;
    }

    .col-exact {
      text-align: center;

      @include smartphone-only {
        text-align: right;
      }

      > span {
        position: relative;

        svg {
          position: absolute;
          bottom: 25px;
          right: -20px;
        }

        &:hover {
          cursor: pointer;

          > p {
            display: block;
          }
        }
      }
    }

  }

  .as-form {
    .row {
      margin-bottom: 1em;
      align-items: center;

      input[type="text"] {
        height: 40px;
        font-size: 0.875em;
        font-weight: 300;
        border: none;
        background-color: $grey-background;
      }

      .form-check-input {
        text-align: end;
      }
    }
  }

  label {
    margin: 0;
  }

  button {
    margin: 2em auto 0 auto;
    display: block;
  }
}
</style>
